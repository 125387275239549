<template>
    <b-card no-body class="p-1">
        <froala :config="config" v-model="model"/>
        <froalaView v-model="model" :config="config"/>
        <input @input="upload" v-model="text"/>
        {{output}}
    </b-card>
</template>

<script>
    // eslint-disable-next-line no-unused-vars
    import VueFroala from 'vue-froala-wysiwyg';

    import {
        BCard,
    } from 'bootstrap-vue'

    export default {
        name: "TestComponent",
        components: {
            BCard,
        },
        data() {
            return {
                config: {
                    events: {
                        'initialized': function () {
                            console.log('floara inited')
                        }
                    },
                    language: 'fa',
                    direction: 'rtl',
                    tooltips: false,
                    toolbarButtons : {
                        'moreText': {
                            'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                        },
                        'moreParagraph': {
                            'buttons': ['alignLeft', 'alignCenter','alignRight', 'formatOLSimple', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
                        },
                        'moreRich': {
                            'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR']
                        },
                        'moreMisc': {
                            'buttons': ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
                            'align': 'right',
                            'buttonsVisible': 2
                        }
                    }
                },
                model: '',
                text:'',
                output:'',
            }
        },
        methods: {
            upload() {
                this.output = this.text.match(/<iframe(.*?)<\/iframe>/)[0]
                navigator.clipboard.writeText(this.output)
                console.log(this.output)
            }
            // uploader(editor)
            // {
            //     editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
            //         return new Base64UploadAdapter( loader );
            //     };
            // },
        }
    }
</script>

<style>

</style>